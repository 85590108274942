import React from "react"
import PropTypes from "prop-types"
import { Hero } from "../components/StandartPage/index"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
  },
}) => (
  <div>
    <Seo
      title="Etiketler"
      description="Bilişim Avukatı Etiketler Sayfası, Bilişim Avukatı, Fenomen Avukatı, Influencer Avukatı"
    />
    <div>
      <Hero title="Etiketler:" />
      <div className="tags">
        <div className="section">
          <ul className="wrap">
            {group.map(tag => (
              <li key={tag.fieldValue}>
                <Link
                  className="btn nobg"
                  to={`/tags/${kebabCase(tag.fieldValue)}/`}
                >
                  {tag.fieldValue} ({tag.totalCount})
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
)

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
